import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import EnhancedTable from "../../Commen_Component/EnhancedTable";
import { userServicer } from "../../Commen_Services/user.services";
import { AccessLevel, PageEnums } from "../../../_enums/enum.services";
import AddDesignation from "../Designation/AddDesignation";
import EnhancedSearchbar from "../../Commen_Component/EnhancedSearchbar";

interface ColumnData {
    autoSearchKey: string;
    tableFieldName: string;
    headerName: string;
}

interface RowData {
    [key: string]: any;
}

interface ReportConfigData {
    viewButtonsSum: number;
}

const Designation: React.FC<{ pageId: any }> = ({ pageId }) => {

    const { zpageID } = useParams();
    //let pageId1 = pageId || zpageID;
    let pageId1 = PageEnums.Designation;
    const [columnData, setColumnData] = useState<ColumnData[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [pageCount, setPageCount] = useState(0);
    const [tableData, setTableData] = useState<RowData[]>([]);
    const [csvData, setCSVData] = useState<RowData[]>([]);
    const [reportConfigData, setreportConfigData] = useState<RowData[]>([]);
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: string }>({
        key: '',
        direction: 'asc',
    });
    const [firtpageNum, setfirstPageNum] = useState(1);
    const [secondpagenum, setSecondPagenum] = useState(perPage);

    const [viewButtonSum, setButtonSum] = useState<number[]>([]);

    const [dataFromChild, setDataFromChild] = useState<RowData>({});


    const [filterFormData, setFilterFormData] = useState<{ key: string; value: any }[]>([]);

    const [fullWidth, setFullWidth] = useState(false);

    const [first, setFirst] = useState(false);

    const handleFilterData = (event: { key: string; value: any }[]) => {

        console.log(event);
        setFilterFormData(event);

    }
    const [tableColumns, settableColumns] = React.useState<RowData[]>(columnData);
    const [editRowData, setEditRowData] = React.useState<RowData[]>();
    const [refreshData, setRefreshData] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState({});
    const [isViewStatus, setIsViewStatus] = React.useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const t1 = localStorage.getItem('isHidden');
        t1 == 'false' ? setFullWidth(false) : setFullWidth(true);

    },[localStorage.getItem('isHidden')]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [tableConfig, reportConfig] = await Promise.all([
                    userServicer.tableConfig(pageId1),
                    userServicer.reportConfig(pageId1)
                ]);
                setColumnData(tableConfig.data);
                setreportConfigData(reportConfig.data);
                setButtonSum(findSumElements(reportConfig.data.viewButtonsSum));
            } catch (error) {
                //console.error('Error fetching data:', error);
                navigate("/Pagenotfound")
            }
        };

        const findSumElements = (target: number): number[] => {
            //const arr = Object.values(AccessLevel);
            const arr = Object.values(AccessLevel);
            //const arr = [-6, -5, -4, -3, -2, -1, 0, 1, 2, 4, 8, 16, 32, 64];
            let result: number[] = [];
            let n = arr.length;
            for (let i = 0; i < (1 << n); i++) {
                let sum = 0;
                let subset: number[] = [];
                for (let j = 0; j < n; j++) {
                    if (i & (1 << j)) {
                        sum += arr[j];
                        subset.push(arr[j]);
                    }
                }
                if (sum === target) {
                    result = subset;
                    break;
                }
            }
            return result;
        };

        fetchData();
    }, [pageId1]);

    useEffect(() => {
        const fetchData = async () => {
            console.log(reportConfigData);
            console.log(filterFormData.length);
            console.log(paginationData);
            
            if (!reportConfigData) return;
            if (!filterFormData) return;
            if (!paginationData) return;

            try {
                const tabledata1 = await userServicer.getReportlist(Number(pageId1), filterFormData, paginationData);
                setTableData(tabledata1.data);
                setPageCount(tabledata1.data ? Math.ceil(tabledata1.data.length / perPage) : 1);
                const filteredColumnData = columnData.filter((item: any) => item.showFieldDDL === true);
                const filterColumArray = filteredColumnData.map(item => item.tableFieldName).filter(Boolean);
                const filteredData1 = tabledata1.data.map((item: any) =>
                    Object.keys(item)
                        .filter(key => filterColumArray.includes(key))
                        .reduce((obj: any, key) => {
                            obj[key] = item[key];
                            return obj;
                        }, {})
                );
                settableColumns(filteredColumnData);
                setCSVData(filteredData1);
            } catch (error) {
                
            }
        };  
        
        let x1 = typeof reportConfigData
        console.log((reportConfigData as any).reportName);
        
        if((reportConfigData as any).reportName != undefined)
        {
            fetchData();
        }
        
    }, [pageId1, reportConfigData, filterFormData, paginationData]);


    const handleEditButtondata = (event: RowData[]) => {

        console.log(event);
        setEditRowData(event);
    }
    const handlePagination = (event: any) => {
        console.log(event);
        setPaginationData(event);
        console.log(paginationData);
    }
    const handleViewStatus = (item : any) => {
        let data = {isViewStatus: item}
        setIsViewStatus(data);
    }

    return (
        <>
            <div>
                <div className="shadow">
                    <div className="bg-line">
                        <span className="bg-head"><FaUser /> &nbsp;{(reportConfigData as any).reportName}</span>
                    </div>
                    <hr className="colorgraph" />
                </div>
                <div>
                    <AddDesignation columndata={tableColumns} editRowData={editRowData ? editRowData : []  as RowData[]} sendDatatoParent={setEditRowData} refreshGrid={() => { refreshData ? setRefreshData(false) : setRefreshData(true) }} sendViewStatus={handleViewStatus} />
                </div>
                <div className="mani"><EnhancedSearchbar pageID={String(pageId1)} columnData={columnData} sendDataToParent={handleFilterData} sendFilterData={filterFormData} ReportConfigData={reportConfigData} tableData={csvData} csvHeaderColumns={tableColumns} /></div>

                <div className="card-container">
  
                    {
                        <EnhancedTable pageID={String(pageId1)} buttonSum={viewButtonSum} columnData={columnData} tableData1={tableData} sendDataToParent={handleEditButtondata} sendPaginationDatatoParent={handlePagination} handleViewStatus={isViewStatus} />
                    }


                </div>
            </div>
        </>
    );
}

export default Designation;




