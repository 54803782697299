
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageNotFound from '../../pages/PageNotFound';

import View from './View/View';
import AddorEdit from './AddorEdit/AddorEdit';


const componentPaths: any = {
    view: View,
    Add: AddorEdit

};


const ReportModule = () => {
    const { componentName } = useParams<{ componentName: string }>();
    const [Component, setComponent] = useState<React.ComponentType<any> | null>(null);

    useEffect(() => {
        debugger
        const importComponent = async () => {

            console.log(componentName);
            if (componentName !== undefined) {
                if (componentPaths[componentName]) {
                    setComponent(() => componentPaths[componentName]);
                }
                else {
                    setComponent(() => null)
                }
            }



        };

        importComponent();
    }, [componentName]);

    if (!Component) return <PageNotFound />;

    return <Component />;
};

export default ReportModule;




