import { Add, Close, Save } from "@mui/icons-material";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { designationService } from "../../Commen_Services/Designation.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import customToast from "../../Commen_Component/Toast/CustomToast";
import { userServicer } from "../../Commen_Services/user.services";
import { stateMasterService } from "../../Commen_Services/StateMaster.service";

interface propsHead {
    columndata: any[];
    editRowData: RowData[];
    sendDatatoParent: any;
    refreshGrid: any;
    sendViewStatus: any;

}

interface RowData {
    [key: string]: any;
}



const AddState: React.FC<propsHead> = ({ columndata, editRowData, sendDatatoParent, refreshGrid, sendViewStatus }) => {

    const [inputs, setInputs] = useState<any>({});

    const [disable, setDisable] = useState(true);
    const [editable, setEditable] = useState(true);
    const [error, setError] = useState({
        state_Name: '',
        state_Code: '',
        countryId: ''
    });
    const [use, setUse] = useState(true);
    const [isSave, setIssave] = useState(false);
    const [options, setOptions] = useState([]);







    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        // setInputs(values => ({...values, [name]: value}))
        let newInputs;
        if (name == 'countryId') {
            const value1 = parseInt(value);
            newInputs = { ...inputs, [name]: value1 };
        }
        else {
            newInputs = { ...inputs, [name]: value };
        }

        let newError = { ...error };

        if (name === 'state_Name') {
            if (!/^[a-zA-Z0-9]{1,50}$/.test(value)) {
                newError.state_Name = "Name should be Alphabetic & maximum of 50 characters";
            }
            else if (value.trim() === '') {
                newError.state_Name = "Blank space not allowed";
            }
            else {
                newError.state_Name = '';
            }
        }
        else if (name === 'state_Code') {

            if (!/^[a-zA-Z0-9]{1,10}$/.test(value)) {
                newError.state_Code = "Code should be Alphabetic & maximum of 20 characters";
            }
            else if (value.trim() === '') {
                newError.state_Name = "Blank space not allowed";
            }
            else {
                newError.state_Code = '';
            }
        } else if (name === 'countryId') {
            const value1 = parseInt(value)
            debugger
            if (value1 > 100) {
                newError.countryId = "Maximum of 2 digits";
            }
            else if (value1 < 0) {
                newError.countryId = "LevelId must be positive";
            }
            else {
                newError.countryId = '';
            }
        }

        // Update state
        setInputs(newInputs);
        setError(newError);

    };


    const handleFocus = (fieldName: any) => {
        if (!inputs[fieldName]) {
            setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter " + fieldName }));
        }
    };

    // const handelSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    
    const handelSubmit = (event: any) => {
        event.preventDefault();
        console.log(inputs);
        console.log(error);
        debugger
        // if (!/^[a-zA-Z0-9]{1,10}$/.test(inputs.designationCode)) {
        //     //setInputs(values => ({ ...values, designationCode: value }));
        //     setError({ ...error, designationCode: "Code should be alphanumeric & maximum of 10 char" });

        // }
        // if (!/^[a-zA-Z0-9\-_]{0,50}$/.test(inputs.designationName)) {
        //     // setInputs(values => ({ ...values, [name]: value }));
        //     setError({ ...error, designationName: "Name should be alphanumeric & maximum of 50 char" });
        // }

        // if (inputs.levelId && inputs.levelId.length > 2) {
        //     //setInputs(values => ({ ...values, [name]: value }));
        //     setError({ ...error, levelId: "Maximum of 2 digits" });
        // }
        if (!inputs.state_Name) {
            setError((prevErrors) => ({ ...prevErrors, state_Name: "Please enter State Name" }));
        }
        if (!inputs.state_Code) {
            setError((prevErrors) => ({ ...prevErrors, state_Code: "Please enter State Code" }));
        }
        if (!inputs.countryId) {
            setError((prevErrors) => ({ ...prevErrors, countryId: "Please Select Country" }));
        }

        if (inputs.state_Name != undefined && inputs.state_Code != undefined && inputs.countryId != undefined) {
            if (error.state_Name == '' && error.state_Code == "" && error.countryId == "") {
                try {

                    console.log(inputs);


                    stateMasterService.SaveorUpdate(inputs).then(user => {
                        debugger
                        if (user.statusCode == 200) {
                            if (user.status == false) {
                                customToast.Warning(user.message);
                                closeForm();
                                refreshGrid(true);
                            }
                            else {
                                customToast.success("Data saved Successfully");
                                closeForm();
                                refreshGrid(true);

                            }
                            //customToast.showToast("Warning: Something went wrong", "warning");

                            // setInputs({});
                            // setDisable(true);
                        }
                    });
                }
                catch (ex) {
                    customToast.error("Something went wrong");
                }
            }



        }


    }

    const handleChangeMUI = (event: any) => {
        debugger
        const name = event.target.name;
        const value = event.target.value;
        console.log(name + ":" + value);
        setError((prevErrors) => ({ ...prevErrors, countryId: '' }));
        setInputs((values: any) => ({ ...values, [name]: value }))


        //setSelectedField(values => ({ ...values, [name]: value }));
    }

    const closeForm = () => {

        setInputs({ state_Id: 0, isActive: true, state_Code: '', state_Name: '', countryId: '', isViewData: false });
        setError({
            state_Code: '',
            state_Name: '',
            countryId: ''
        });
        sendDatatoParent("");
        sendViewStatus(false);
        debugger
        if (isSave) {
            setDisable(true);
            setEditable(true);
        }

    }

    useEffect(() => {
        debugger

        const fetchStateById = async (state_ID: Number) => {
            let data = await stateMasterService.getstatedatabyid(state_ID);
            editRowData = data.data;
            debugger
            setInputs(editRowData);

        }

        setInputs((values: any) => ({ ...values, state_Id: 0, isActive: true }));
        if ((editRowData as any) != '') {
            console.log(editRowData, 'editRowData')

            fetchStateById((editRowData as any).state_Id);
            // setInputs(editRowData);
            setDisable(false);
            (editRowData as any).isViewData ? setEditable(true) : setEditable(false);

        }



    }, [editRowData, use])

    useEffect(() => {
        closeForm();
    }, [isSave]);

    useEffect(() => {
        const fetcOption = async () => {
            debugger
            const data = await userServicer.getDropDownlist("Country", null);
            const h = data.data;
            const transformedData = h.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(h[0])[0]],
                OptionName: item[Object.keys(h[0])[1]] == undefined ? item[Object.keys(h[0])[0]] : item[Object.keys(h[0])[1]]
            }));
            setOptions(transformedData);
        }

        fetcOption();


    }, [])




    return (<>
        <div style={{ marginLeft: 10 }}>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <form onSubmit={handelSubmit} style={{ display: 'flex', flexDirection: 'row' }} >


                    <TextField

                        sx={{ marginRight: 1 }}
                        id="standard-basic"
                        variant="standard"
                        name="state_Code"
                        value={inputs?.["state_Code"]}
                        label="State Code"
                        disabled={disable}
                        onChange={handleChange}
                        focused={!!inputs.state_Code}
                        onFocus={() => handleFocus("state_Code")}
                        error={!!error.state_Code}
                        helperText={error.state_Code}
                        InputProps={{
                            readOnly: editable,
                            style: {
                                color: '#243C5C',
                                fontSize: '14px',
                                width: '200px',
                                borderColor: error.state_Code ? 'red' : undefined
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#243C5C',
                                fontSize: '12px',
                                borderColor: error.state_Code ? 'red' : undefined
                            },
                        }}
                        FormHelperTextProps={{
                            style: {
                                fontSize: error.state_Code ? '9px' : '12px',

                            },
                        }}
                    />
                    <TextField
                        sx={{ marginRight: 1 }}
                        id="standard-basic"
                        variant="standard"
                        name="state_Name"
                        disabled={disable}
                        value={inputs["state_Name"]}
                        label="State Name"
                        onChange={handleChange}
                        focused={!!inputs.state_Name}
                        onFocus={() => handleFocus('state_Name')}
                        error={!!error.state_Name}
                        helperText={error.state_Name}
                        InputProps={{
                            readOnly: editable,
                            style: {
                                color: '#243C5C',
                                fontSize: '14px',
                                width: '200px',
                                borderColor: error.state_Name ? 'red' : undefined
                            }
                        }}
                        InputLabelProps={{

                            style: {
                                color: '#243C5C',
                                fontSize: '12px',
                                borderColor: error.state_Name ? 'red' : undefined
                            },
                        }}

                        FormHelperTextProps={{
                            style: {
                                fontSize: error.state_Name ? '9px' : '12px',

                            },
                        }}

                    />
                    {/* <FormControl variant="standard" sx={{ m: -0.3, width: 200, minWidth: 120, marginRight: 1 }} error={!!error.countryId} helperText={error.countryId} FormHelperTextProps={{
                        style: {
                            fontSize: error.state_Code ? '9px' : '12px',

                        },
                    }} >
                        <InputLabel id="demo-simple-select-standard-label" sx={{
                            fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                        }}>
                            Country Name
                        </InputLabel>
                        <Select
                            disabled={disable}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            autoComplete="inside"
                            name="countryId"
                            error={!!error.countryId}
                            value={inputs.countryId != "" ? Number(inputs.countryId) : inputs.countryId}
                            onChange={handleChangeMUI}
                            label="Search c"
                            onClose={(e) => e.preventDefault()}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 300, // Set your desired max height
                                        overflowY: 'auto', // Enable vertical scrolling
                                    },
                                },

                            }}
                        >
                            {options.map((item, i) => {
                                console.log(item);

                                return (<MenuItem key={i} sx={{ fontSize: 14 }} value={item['OptionID']}>{item['OptionName']}</MenuItem>)
                            })}
                        </Select>
                    </FormControl> */}
                     <FormControl variant="standard" error={!!error.countryId} sx={{ m: -0.3, width: 180, minWidth: 120, marginRight: 1 }}>
                        <InputLabel id="demo-simple-select-standard-label" sx={{
                            fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                        }}>Country Name</InputLabel>
                        <Select

                            disabled={disable}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            autoComplete="inside"
                            name="countryId"
                            error={!!error.countryId}
                            value={inputs.countryId != "" ? Number(inputs.countryId) : inputs.countryId}
                            onChange={handleChangeMUI}
                            label="Search c"
                            onClose={(e) => e.preventDefault()}

                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 300,
                                        overflowY: 'auto',
                                    },
                                },
                            }}
                        >
                            {options.map((item: any) => (
                                <MenuItem key={item.OptionID} value={item.OptionID}>
                                    {item.OptionName}
                                </MenuItem>
                            ))}
                        </Select>
                        {error.countryId ? (
                            <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.countryId}</FormHelperText>
                        ) : (
                            <FormHelperText sx={{ color: 'white', fontSize: 9 }}>Helper Text</FormHelperText>
                        )}
                    </FormControl>


                    <button className={`btn123 ${disable || editable ? 'inactive' : ''}`} type="submit" style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;{(editRowData as any) != '' ? <>Update</> : <>Save & New</>}</button>&nbsp;
                    <button className={`btn123 ${disable || editable || (editRowData as any) != "" ? 'inactive' : ''}`} onClick={(event) => { handelSubmit(event); setIssave(true) }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;Save</button>
                </form>

                <button className={`btn123 ${disable ? '' : 'inactive'}`} onClick={() => {
                    setDisable(false); setEditable(false); console.log(editRowData);
                    (editRowData as any) = ""; console.log(editRowData);
                    setInputs({});
                    setUse(false);

                }} style={{ paddingRight: 12 }}><Add />&nbsp;Add</button>&nbsp;
                &nbsp;
                <button className={`btn123 ${disable ? 'inactive' : ''}`} onClick={() => { setIssave(true); closeForm() }}  ><Close />&nbsp; Close</button>

            </div>


        </div>
    </>);



}

export default AddState;


