import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Checkbox, TableFooter, ThemeProvider, colors, createTheme, responsiveFontSizes, styled, useTheme } from '@mui/material';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useParams } from 'react-router-dom';
import { Edit, Height, History } from '@mui/icons-material';
import { FaEye } from 'react-icons/fa';
import { IoEye } from 'react-icons/io5';
import Popup from 'reactjs-popup';
import PageNotFound from '../../pages/PageNotFound';
import load from '../../assets/Images/dots.gif';
import customToast from './Toast/CustomToast';


interface ColumnData {
  tableFieldName: string;
  headerName: string;
}

interface RowData {
  [key: string]: any;
}

interface propsHead {
  pageID: string;
  buttonSum: Number[];
  columnData: ColumnData[];
  tableData1: RowData[];
  //sendDataToParent: RowData[];
  sendDataToParent:(event: RowData[]) => void;
  sendPaginationDatatoParent: any;
  handleViewStatus: any;
}

function descendingComparator(a: any, b: any, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator(order: Order, orderBy: string) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any[], comparator: (a: any, b: any) => number) {
  //console.log(array);

  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  order: Order;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}

interface PaginationDataforParent {
  pageSize: Number;
  pagenumber: Number;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const tableTheme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {

          lineHeight: '5px',
        }
      }
    }
  }
})

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  root: {
    height: "5px"
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#B3C9E6',
    color: '#243C5C',


  },
  // hide last border
  '& td, &:last-child th': {
    border: 0,
    backgroundColor: '#B3C9E6',
    color: '#243C5C',
    height: '5px',
    fontSize: '12px',
    fontWeight: '700'

  },
}));







function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;



  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


const EnhancedTable: React.FC<propsHead> = ({ pageID, buttonSum, columnData, tableData1, sendDataToParent, sendPaginationDatatoParent, handleViewStatus }) => {

  debugger
  const { zpageID } = useParams();
  //let pageId1 = pageID.pageID || zpageID;
  let pageId1 = pageID || zpageID;
  const [open, setOpen] = React.useState<boolean>(false);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('id');
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [viewButtonSum, setButtonSum] = React.useState<Number[]>([]);
  const [isView, setIsView] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [paginationdata, setPaginationData] = React.useState({});

  const [tableColumns, settableColumns] = React.useState<RowData[]>(columnData);

  const [tableData, setTableData] = React.useState<RowData[]>(tableData1);

  const [loader, setLoader] = React.useState(true);

  const [totalRecord, setTotalRecord ] = React.useState(0);

  

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

  };

  const handleRowClick = (id: number) => {
    alert(id);
  }

  const handleEditButtonData = (data: RowData) => {

    console.log(handleViewStatus);

    if (isView == true) {
      customToast.Warning('View Task is running');
    }
    else {
      setIsEdit(true);
      data.isViewData = false;
      sendDataToParent(data as RowData[]);
    }


  }

  const handleViewButtonData = (data: RowData) => {
    debugger

    data.isViewData = true;
    if (isEdit == true) {
      customToast.Warning('Edit Task is running');
    }
    else {
      setIsView(true);

      sendDataToParent(data as RowData[]);
    }

  }

  const handleHistoryButton = (data: any) => {
    setOpen(true);
  }

  React.useEffect(() => {
    setTableData(tableData1);
    setButtonSum(buttonSum);
    
    tableData1 != null && tableData1.length > 0 && setLoader(false);
    
    let x = tableData1.map(item => item.totalRecord);
    console.log(x[0]);
    setTotalRecord(x[0]);
    const filteredColumnData = columnData != undefined && columnData.filter((item: any) => item.isDisplay === true);
    settableColumns(filteredColumnData as RowData[]);


  }, [tableData1, columnData, buttonSum]);

  React.useEffect(() => {
    setPage(0)
  },[totalRecord])

  React.useEffect(() => {
   setPaginationData({ pageSize: rowsPerPage, pageNumber: page + 1 })

  }, [page, rowsPerPage])

  React.useEffect(() => {
    sendPaginationDatatoParent(paginationdata);
    console.log(paginationdata);
    
  }, [paginationdata])

  React.useEffect(() => {
    debugger
    console.log(" Data :" + handleViewStatus.isViewStatus);
    if (handleViewStatus.isViewStatus == false) {
      setIsView(false);
      setIsEdit(false);
    }

  }, [handleViewStatus])

  let len = tableData ? tableData.length : 0;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, len - page * rowsPerPage);

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

    return (
      <ThemeProvider theme={tableTheme}>

        <TableHead sx={{ '& th': { height: '10px' } }}>
          <StyledTableRow>
            {tableColumns.map((column) => (
              <TableCell
                key={column.tableFieldName}
                align={column.numeric ? 'right' : 'left'}
                sortDirection={orderBy === column.tableFieldName ? order : false}
                style={{ paddingTop: 3, paddingBottom: 3, }}
              >
                <TableSortLabel
                  active={orderBy === column.tableFieldName}
                  direction={orderBy === column.tableFieldName ? order : 'asc'}
                  onClick={createSortHandler(column.tableFieldName)}
                  style={{ paddingTop: 0, paddingBottom: 0, }}
                >
                  {column.headerName}
                  {orderBy === column.tableFieldName ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell style={{ paddingTop: 3, paddingBottom: 3, }}>&nbsp;</TableCell>
          </StyledTableRow>
        </TableHead>

      </ThemeProvider>
    );
  }

  tableData && (tableData[0] !== undefined && console.log(tableData[0]['totalRecord']));


  return (
    <Paper sx={{ width: '100%', height: '65vh', marginBottom: 2 }}>
      <TableContainer sx={{ maxHeight: 350 }}>
        <Table
          sx={{ minWidth: 50, overflowY: 'auto', }}
          aria-labelledby="tableTitle"
          stickyHeader aria-label="sticky table"

        >

          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />


          <TableBody>
            {
              loader ?
                <>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={load} height="300px" alt="Empty State" />
                  </div>
                </>
                : tableData?.length > 0 ? stableSort(tableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      style={{ height: 2, fontSize: 10 }}
                    >{
                        tableColumns.map((column) => {
                          if (column.fieldType == 7) {
                            return (
                              <TableCell key={column.id} style={{ height: 2, paddingTop: 0, paddingBottom: 0, fontSize: 12 }} align={column.numeric ? 'right' : 'left'}>
                                {row[column.tableFieldName] == true ? <><input type='checkbox' checked readOnly /></> : <><input type='checkbox' readOnly /></>}
                              </TableCell>

                            );
                          }
                          else {
                            return (
                              <TableCell key={column.id} style={{ height: 2, paddingTop: 0, paddingBottom: 0, fontSize: 12 }} align={column.numeric ? 'right' : 'left'}>
                                {row[column.tableFieldName]}
                              </TableCell>
                            );
                          }

                        })
                      }
                      {/* {tableColumns.map((column) => (
                    
                    <TableCell key={column.id} style={{ height: 2, paddingTop:0,paddingBottom:0,fontSize: 12 }} align={column.numeric ? 'right' : 'left'}>
                      {row[column.tableFieldName]}
                    </TableCell>
                  ))} */}
                      {/* {
                      buttonSum.map((item) => (
                       <h3>{item}</h3>
                      ))
                    } */}
                      <TableCell key={row.id} style={{ height: 2, paddingTop: 0, paddingBottom: 0, fontSize: 8 }} >
                        <IconButton aria-label="select" sx={{ color: '#243C5C' }} onClick={(event) => { event.stopPropagation(); handleRowClick(row.id); }}><DeleteIcon /></IconButton>
                        <IconButton aria-label="select" sx={{ color: '#243C5C' }} onClick={(event) => { event.stopPropagation(); handleEditButtonData(row); }}><Edit /></IconButton>
                        <IconButton aria-label="select" sx={{ color: '#243C5C' }} onClick={(event) => { event.stopPropagation(); handleViewButtonData(row); }}><IoEye /></IconButton>
                        <IconButton aria-label="select" sx={{ color: '#243C5C' }} onClick={(event) => { event.stopPropagation(); handleHistoryButton(row); }}><History /></IconButton>
                      </TableCell>

                    </TableRow>
                  ))
                  :
                  <TableRow  >
                    <TableCell colSpan={tableColumns.length} style={{ height: 2, paddingTop: 0, paddingBottom: 0, fontSize: 8 }} >
                      <PageNotFound />
                    </TableCell>
                  </TableRow>


            }
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 50 * emptyRows }}>
                <TableCell colSpan={tableColumns.length} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tableData && (tableData[0] !== undefined && tableData[0]['totalRecord'])}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      <Popup contentStyle={{ height: 250, overflowY: "auto", width: 450, padding: 0 }} open={open} onClose={() => { setOpen(false); } }  >
              <>History</>
      </Popup>

    </Paper>


  );
}

export default EnhancedTable;
