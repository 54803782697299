import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { Download } from '@mui/icons-material';

interface ExportToCSVProps {
    csvData: RowData[];
    fileName: string;
    headerName: RowData[];
}

interface RowData {
    [key: string]: any;
}

const ExportToCSV: React.FC<ExportToCSVProps> = ({ csvData, fileName, headerName }) => {
    
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    console.log(headerName);

    
    const exportToCSV = (csvData: RowData[], fileName: string, headerName: any[]) => {
        // Customize headers
        const headers = headerName.map(name => ({ label: name.headerName, key: name.tableFieldName }));
    
        // Sort headers based on displayOrder
        headers.sort((a, b) => {
            return headerName.find(header => header.tableFieldName === a.key)?.displayOrder - 
                headerName.find(header => header.tableFieldName === b.key)?.displayOrder;
        });
        
        // Customize data according to header order
        const data = csvData.map(row => {
            const newRow: any = {};
            headers.forEach(header => {
                newRow[header.label] = row[header.key];
            });
            return newRow;
        });
    
        console.log(data);
        
        // Create worksheet
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    
        // Generate Excel buffer
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const excelData = new Blob([excelBuffer], { type: fileType });
        console.log(excelData);
        
    
        // Save the file
        FileSaver.saveAs(excelData, fileName + fileExtension);
    }
    

    return (
        <button className="btn12" style={{ fontSize: 18, paddingTop: 6 }} onClick={(e) => exportToCSV(csvData, fileName, headerName)} ><Download /></button>
    );
}

export default ExportToCSV;

