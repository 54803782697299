// customToast.js
import { Warning } from '@mui/icons-material';
import { error } from 'console';
import { toast } from 'react-toastify';


const customToast = {
  success(msg : any, options = {}) {
    return toast.success(msg, {
      ...options,
       className: `toast-${"green"}`
    //className: 'toast-warning'

    });
  },

  Warning(msg: any, options ={}) {
    return toast.warning(msg, {
      ...options,
      className: `toasr-${"red"}`
    });
  },

  error(msg: any, options = {}) {
    return toast.error(msg,{
      ...options,
      className: `toast-${"red"}`
    })
  }
  
};

export default customToast;

// import React from 'react';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import '../../../index.css';

// const customToast =  {
  
//      showToast(message: any, type: any)  {
//     let className = '';
//     if (type === 'success') {
//       className = 'toast-success';
//     } else if (type === 'warning') {
//       className = 'toast-warning';
//     }
//     toast(message, {
//       className: className,
//     });
//   }

 
// };

// export default customToast;
