import React from "react";
import { FaUser } from "react-icons/fa";

const AddHeadquater = () => {

    return (
        <>
            <div>
                <div className="shadow">
                    <div className="bg-line">
                        <span className="bg-head"><FaUser /> &nbsp;Add Headquater</span>
                        {/* <span className="bg-head margin1" >Headquater &nbsp;<GrNext />&nbsp;Headquater List</span> */}
                    </div>
                    <hr className="colorgraph" />
                </div>
            </div>
        </>
    );
}

export default AddHeadquater;