import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate, useParams, BrowserRouter } from 'react-router-dom';
import Login from './pages/Login/Login';
import { ToastContainer } from 'react-toastify';
import MasterRouting2 from './App/Masters/MasterRouting2';
import CposRoutingmodule from './App/Module/CposRoutingmodule';
import Popup from 'reactjs-popup';
import Logoutpopup from './pages/Login/Logoutpopup';


function App() {

  const [token, setToken] = useState(localStorage.getItem('user'));
  const [showlogoutPopup, setlogoutShowPopup] = useState(false);

  // useEffect(() => {

  //   const storedToken = JSON.parse(localStorage.getItem('user') ? localStorage.getItem('user') : '');
  //   if (storedToken) {
  //     setToken(storedToken.access_token);
  //   }
  // }, []);

  const handleLogin = (newToken: any) => {

    console.log(newToken);

    localStorage.setItem('user', JSON.stringify(newToken.data));
    setToken(newToken.data.access_token);
  };

  const handleLogout = () => {

    localStorage.removeItem('user');
    setToken(null);
  };

  // useEffect(() => {
  //   let timeout: NodeJS.Timeout;

  //   const resetTimeout = () => {
  //     if (timeout) {
  //       clearTimeout(timeout);
  //     }
  //     timeout = setTimeout(() => {
  //       setlogoutShowPopup(true);
  //     }, 10000); // 60 seconds
  //   };

  //   const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
  //   const resetTimeoutHandler = () => resetTimeout();

  //   events.forEach(event => {
  //     document.addEventListener(event, resetTimeoutHandler);
  //   });


  //   resetTimeout();

  //   // Clean up event listeners on component unmount
  //   return () => {
  //     events.forEach(event => {
  //       document.removeEventListener(event, resetTimeoutHandler);
  //     });
  //     clearTimeout(timeout);
  //   };
  // }, []);



  return (

    // <Router>
    //   <Routes>
    //     <Route path='/' element={token ? <Dashboard onLogOut={handleLogout} /> : <Login onLogin={handleLogin} />}>
    //       <Route path="/Headquater" element={<Headquater pageId={"4"} />} />
    //       <Route path="/Headquater/:zpageID" element={<Headquater pageId={useParams<{ zpageID: string }>().zpageID} />} />
    //       <Route path="/Designation" element={<Headquater pageId={"5"} />} />
    //     </Route>
    //   </Routes>
    // </Router >

    <Router>
      <Routes>
        <Route path="" element={token ? <CposRoutingmodule onLogout={handleLogout} /> : <Login onLogin={handleLogin} />} >
          <Route path='/Master/:componentName' element={<CposRoutingmodule onLogout={handleLogout} />} >
            <Route path=':action' element={<MasterRouting2 />} />
          </Route>
          <Route path="/Report" element={<CposRoutingmodule onLogout={handleLogout} />} >
            <Route path=":componentName/:pageId" element={<CposRoutingmodule onLogout={handleLogout} />} />
          </Route>
        </Route>

      </Routes>
      <ToastContainer position="top-center" />
      {/* <Popup contentStyle={{ height: 250, overflowy: "auto", width: 450, padding: 0 }} open={showlogoutPopup} onClose={() => setlogoutShowPopup(false)} children={undefined}>
        <Logoutpopup />
      </Popup> */}
      
    </Router>
  );
}

export default App;
