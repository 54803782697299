import { Add, Close, Save } from "@mui/icons-material";
import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { designationService } from "../../Commen_Services/Designation.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import customToast from "../../Commen_Component/Toast/CustomToast";
import { userServicer } from "../../Commen_Services/user.services";
import { stateMasterService } from "../../Commen_Services/StateMaster.service";
import { CityMasterService } from "../../Commen_Services/CityMaster.service";
import { PageEnums } from "../../../_enums/enum.services";

interface propsHead {
    columndata: any[];
    editRowData: RowData[];
    sendDatatoParent: any;
    refreshGrid: any;
    sendViewStatus: any;

}

interface RowData {
    [key: string]: any;
}

const AddCity: React.FC<propsHead> = ({ columndata, editRowData, sendDatatoParent, refreshGrid, sendViewStatus }) => {
    console.log(editRowData, 'editRowData');


    const [inputs, setInputs] = useState<any>({});

    const [disable, setDisable] = useState(true);
    const [editable, setEditable] = useState(true);
    const [error, setError] = useState({
        city_Name: '',
        city_Code: '',
        countryId: '',
        stateId: ''
    });
    const [use, setUse] = useState(true);
    const [isSave, setIssave] = useState(false);
    const [options, setOptions] = useState([]);
    const [options2, setOptions2] = useState([{ OptionID: 1, OptionName: "--Select State--" }]);
    const [selectedCoutnry, setSelectedCountry] = useState(null);





    useEffect(() => {
        const fetchState = async () => {
            console.log(selectedCoutnry);
            let statList1 = await userServicer.getDropDownlist("StateMaster", Number(selectedCoutnry))

            let statList = statList1.data;
            const transformedData1 = statList.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(statList[0])[0]],
                OptionName: item[Object.keys(statList[0])[1]] == undefined ? item[Object.keys(statList[0])[2]] : item[Object.keys(statList[0])[1]]
            }));

            setOptions2(transformedData1);

        }


        if (!!selectedCoutnry) {
            fetchState();
        }
    }, [selectedCoutnry])


    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        // setInputs(values => ({...values, [name]: value}))
        let newInputs;
        if (name == 'stateId') {
            const value1 = parseInt(value);
            newInputs = { ...inputs, [name]: value1 };
        }
        else {
            newInputs = { ...inputs, [name]: value };
        }

        let newError = { ...error };

        if (name === 'city_Name') {
            if (!/^[a-zA-Z]{1,20}$/.test(value)) {
                newError.city_Name = "Name should be Alphabetic & maximum of 20 characters";
            } else {

                // let data = [{key : name, value: value}]
                // const data1 = await userServicer.getReportlist(PageEnums.City,data,undefined);
                // console.log(data1);
                newError.city_Name = '';
            }
        }
        else if (name === 'city_Code') {

            if (!/^[a-zA-Z]{1,3}$/.test(value)) {
                newError.city_Code = "Code should be Alphabetic & maximum of 3 characters";
            }
            else {
                newError.city_Code = '';
            }
        } else if (name === 'stateId') {
            const value1 = parseInt(value)
            debugger
            if (value1 > 100) {
                newError.stateId = "Maximum of 2 digits";
            }
            else if (value1 < 0) {
                newError.stateId = "LevelId must be positive";
            }
            else {
                newError.stateId = '';
            }
        }

        // Update state
        setInputs(newInputs);
        setError(newError);

    };


    const handleFocus = (fieldName: any) => {
        if (!inputs[fieldName]) {
            setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter " + fieldName }));
        }
    };

    // const handelSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const handelSubmit = (event: any) => {
        event.preventDefault();
        console.log(inputs);
        console.log(error);
        debugger
        // if (!/^[a-zA-Z0-9]{1,10}$/.test(inputs.designationCode)) {
        //     //setInputs(values => ({ ...values, designationCode: value }));
        //     setError({ ...error, designationCode: "Code should be alphanumeric & maximum of 10 char" });

        // }
        // if (!/^[a-zA-Z0-9\-_]{0,50}$/.test(inputs.designationName)) {
        //     // setInputs(values => ({ ...values, [name]: value }));
        //     setError({ ...error, designationName: "Name should be alphanumeric & maximum of 50 char" });
        // }

        // if (inputs.levelId && inputs.levelId.length > 2) {
        //     //setInputs(values => ({ ...values, [name]: value }));
        //     setError({ ...error, levelId: "Maximum of 2 digits" });
        // }
        if (!inputs.city_Name) {
            setError((prevErrors) => ({ ...prevErrors, city_Name: "Please enter State Name" }));
        }
        if (!inputs.city_Code) {
            setError((prevErrors) => ({ ...prevErrors, city_Code: "Please enter State Code" }));
        }
        if (!inputs.stateId) {
            setError((prevErrors) => ({ ...prevErrors, stateId: "Please Select state" }));
        }
        if (!selectedCoutnry) {
            setError((prevErrors) => ({ ...prevErrors, countryId: "Please Select Country" }));
        }

        if (inputs.city_Name != undefined && inputs.city_Code != undefined && inputs.stateId != undefined) {
            if (error.city_Name == '' && error.city_Code == "" && error.stateId == "") {
                try {

                    console.log(inputs);


                    CityMasterService.SaveorUpdate(inputs).then(user => {
                        debugger
                        if (user.statusCode == 200) {
                            if (user.status == false) {
                                customToast.Warning(user.message);
                                closeForm();
                                refreshGrid(true);
                            }
                            else {
                                customToast.success("Data saved Successfully");
                                closeForm();
                                refreshGrid(true);


                            }
                            //customToast.showToast("Warning: Something went wrong", "warning");

                            // setInputs({});
                            // setDisable(true);
                        }
                    });
                }
                catch (ex) {
                    customToast.error("Something went wrong");
                }
            }



        }


    }

    const handleChangeMUIforCountry = (event: any) => {
        debugger
        const name = event.target.name;
        const value = event.target.value;
        console.log(name + ":" + value);
        setSelectedCountry(value);
        //  setInputs((values: any ) => ({ ...values, [name]: value }))

    }

    const handleChangeMUI = (event: any) => {
        debugger
        const name = event.target.name;
        const value = event.target.value;
        console.log(name + ":" + value);
        setError((prevErrors) => ({ ...prevErrors, stateId: '' }));
        setInputs((values: any) => ({ ...values, [name]: value }))


        //setSelectedField(values => ({ ...values, [name]: value }));
    }

    const closeForm = () => {

        setInputs({ state_Id: 0, isActive: true, city_Code: '', city_Name: '', stateId: '', isViewData: false });
        setError({
            city_Code: '',
            city_Name: '',
            countryId: '',
            stateId: ''
        });
        setSelectedCountry(null);
        sendDatatoParent("");
        sendViewStatus(false);
        debugger
        if (isSave) {
            setDisable(true);
            setEditable(true);
        }

    }

    useEffect(() => {
        debugger

        const fetchStateById = async (city_ID: Number) => {
            let data = await CityMasterService.getcitydatabyid(city_ID);
            editRowData = data.data;
            console.log(editRowData);

            debugger
            setInputs(editRowData);
            setSelectedCountry((editRowData as any).countryId);

        }

        setInputs((values: any) => ({ ...values, city_Id: 0, isActive: true }));
        if ((editRowData as any) != "") {

            fetchStateById((editRowData as any).city_Id);
            // setInputs(editRowData);
            setDisable(false);
            (editRowData as any).isViewData ? setEditable(true) : setEditable(false);

        }



    }, [editRowData, use])

    useEffect(() => {
        closeForm();
    }, [isSave]);

    useEffect(() => {
        const fetcOption = async () => {
            debugger
            const data = await userServicer.getDropDownlist("Country", null);
            const h = data.data;
            const transformedData = h.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(h[0])[0]],
                OptionName: item[Object.keys(h[0])[1]] == undefined ? item[Object.keys(h[0])[0]] : item[Object.keys(h[0])[1]]
            }));
            setOptions(transformedData);
        }

        fetcOption();


    }, [])

    let val3 = { OptionName: '--Select State--' };

    if (options !== undefined && options !== null && inputs["stateId"] !== undefined && inputs["stateId"] !== null) {
        let val2 = options2.filter(item => item.OptionID == inputs["stateId"]);

        let val4 = val2.length > 0 ? Object.values(val2[0]) : '';

        val3.OptionName = val4[1] != undefined ? String(val4[1]) : '';

    } else {
        console.log('options or inputs[field.tableFieldName] is undefined or null');
    }



    console.log(typeof editRowData);

    return (<>
        <div style={{ marginLeft: 10 }}>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <form onSubmit={handelSubmit} style={{ display: 'flex', flexDirection: 'row' }} >


                    <TextField

                        sx={{ marginRight: 1 }}
                        id="standard-basic"
                        variant="standard"
                        name="city_Code"
                        value={inputs["city_Code"]}
                        label="City Code"
                        disabled={disable}
                        onChange={handleChange}
                        focused={!!inputs.city_Code}
                        onFocus={() => handleFocus("city_Code")}
                        error={!!error.city_Code}
                        helperText={error.city_Code}
                        InputProps={{
                            readOnly: editable,
                            style: {
                                color: '#243C5C',
                                fontSize: '14px',
                                width: '200px',
                                borderColor: error.city_Code ? 'red' : undefined
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#243C5C',
                                fontSize: '12px',
                                borderColor: error.city_Code ? 'red' : undefined
                            },
                        }}
                        FormHelperTextProps={{
                            style: {
                                fontSize: error.city_Code ? '9px' : '12px',

                            },
                        }}
                    />
                    <TextField
                        sx={{ marginRight: 1 }}
                        id="standard-basic"
                        variant="standard"
                        name="city_Name"
                        disabled={disable}
                        value={inputs["city_Name"]}
                        label="City Name"
                        onChange={handleChange}
                        focused={!!inputs.city_Name}
                        onFocus={() => handleFocus('city_Name')}
                        error={!!error.city_Name}
                        helperText={error.city_Name}
                        InputProps={{
                            readOnly: editable,
                            style: {
                                color: '#243C5C',
                                fontSize: '14px',
                                width: '200px',
                                borderColor: error.city_Name ? 'red' : undefined
                            }
                        }}
                        InputLabelProps={{

                            style: {
                                color: '#243C5C',
                                fontSize: '12px',
                                borderColor: error.city_Name ? 'red' : undefined
                            },
                        }}

                        FormHelperTextProps={{
                            style: {
                                fontSize: error.city_Name ? '9px' : '12px',

                            },
                        }}

                    />
                    {/* <FormControl variant="standard" sx={{ m: -0.3, width: 180, minWidth: 120, marginRight: 1 }} error={!!error.stateId} helperText={error.stateId} FormHelperTextProps={{
                        style: {
                            fontSize: error.stateId ? '9px' : '12px',

                        },
                    }} >
                        <InputLabel id="demo-simple-select-standard-label" sx={{
                            fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                        }}>
                            Country Name
                        </InputLabel>
                        <Select
                            disabled={disable}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            autoComplete="inside"
                            name="countryID"
                            error={!!error.countryId}
                            //value={editRowData != undefined ? Number(inputs.stateId) : '' }
                            value={selectedCoutnry}
                            onChange={handleChangeMUIforCountry}
                            label="Search c"
                            onClose={(e) => e.preventDefault()}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 300, // Set your desired max height
                                        overflowY: 'auto', // Enable vertical scrolling
                                    },
                                },

                            }}
                        >
                            {options.map((item, i) => {
                                console.log(item);

                                return (<MenuItem key={i} sx={{ fontSize: 14 }} value={item['OptionID']}>{item['OptionName']}</MenuItem>)
                            })}
                        </Select>
                        {error.countryId ? <FormHelperText sx={{color: 'red',fontSize: 9}}>{error.countryId}</FormHelperText> : <FormHelperText sx={{color: 'white',fontSize: 9}}>{error.countryId}</FormHelperText>}
                    </FormControl> */}

                    

                    <FormControl focused={!!inputs.countryId} variant="standard" error={!!error.countryId} sx={{ m: -0.3, width: 180, minWidth: 120, marginRight: 1 }}>
                        <InputLabel id="demo-simple-select-standard-label" sx={{
                            fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                        }}>Country Name</InputLabel>
                        <Select
                        
                            disabled={disable}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            autoComplete="inside"
                            name="countryId"
                            error={!!error.countryId}
                            //value={inputs.countryId == null ? null : Number(inputs.countryId)}
                            value={selectedCoutnry}
                            
                            onChange={handleChangeMUIforCountry}
                            label="Search c"
                            onClose={(e) => e.preventDefault()}

                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 300,
                                        overflowY: 'auto',
                                    },
                                },
                            }}
                        >
                            {options.map((item: any) => (
                                <MenuItem key={item.OptionID} value={item.OptionID}>
                                    {item.OptionName}
                                </MenuItem>
                            ))}
                        </Select>
                        {error.countryId ? (
                            <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.countryId}</FormHelperText>
                        ) : (
                            <FormHelperText sx={{ color: 'white', fontSize: 9 }}>Helper Text</FormHelperText>
                        )}
                    </FormControl>


                    {/* <Autocomplete
                                    disabled={disable}
                                   disablePortal
                                   id="combo-box-demo"
                                   options={options}
                                   onChange={handleChangeMUIforCountry}
                                   getOptionLabel={(option) => option.OptionName}
                                   getOptionKey={(option) => option.OptionID}
                                   sx={{ width: 180,overflowY: 'auto', overflowX: 'hidden', marginRight: 1 }}
                                   
                                   renderInput={(params) => (
                                       <TextField
                                
                                           {...params}
                                           label="Country Name"
                                           variant="standard"
                                           name="countryID"
                                        InputLabelProps={{
                                            style: {
                                                color: '#243C5C',
                                                fontSize: '12px',
                                                borderColor: 'red',

                                            },
                                        }}

                                       />
                                   )}
                               /> */}
                    <FormControl>
                        <Autocomplete
                            disabled={disable}
                            disablePortal
                            id="combo-box-demo"
                            options={options2}
                            //onChange={handleChangeMUI}
                            onChange={(event, newValue: any) => {
                                setInputs((values: any) => ({ ...values, stateId: newValue != null ? newValue.OptionID : '' }));
                                setError((prevErrors) => ({ ...prevErrors, stateId: '' }));

                            }}
                            getOptionLabel={(option) => option.OptionName}
                            getOptionKey={(option) => option.OptionID}
                            sx={{ width: 180, overflowY: 'auto', overflowX: 'hidden', marginRight: 1 }}
                            value={val3}
                            renderInput={(params) => (
                                <TextField
                                    error={!!error.stateId}
                                    {...params}
                                    label="State Name"
                                    variant="standard"
                                    name="stateId"
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: 'red',

                                        },
                                    }}

                                />

                            )}
                        />
                        {error.countryId ? <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.stateId}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}>{error.stateId}</FormHelperText>}
                    </FormControl>

                    {/* <FormControl variant="standard" sx={{ m: -0.3, width: 180, minWidth: 120, marginRight: 1 }} error={!!error.stateId} helperText={error.stateId} FormHelperTextProps={{
                        style: {
                            fontSize: error.stateId ? '9px' : '12px',

                        },
                    }} >
                        <InputLabel id="demo-simple-select-standard-label" sx={{
                            fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                        }}>
                            State Name
                        </InputLabel>
                        <Select
                            disabled={disable}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            autoComplete="inside"
                            name="stateId"
                            error={!!error.stateId}
                            value={inputs.stateId != "" ? Number(inputs.stateId) : inputs.stateId }
                            onChange={handleChangeMUI}
                            label="Search c"
                            onClose={(e) => e.preventDefault()}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 300, // Set your desired max height
                                        overflowY: 'auto', // Enable vertical scrolling
                                    },
                                },

                            }}
                        >
                            {options2.map((item, i) => {
                                console.log(item);

                                return (<MenuItem key={i} sx={{ fontSize: 14 }} value={item['OptionID']}>{item['OptionName']}</MenuItem>)
                            })}
                        </Select>
                    </FormControl> */}


                    <button className={`btn123 ${disable || editable ? 'inactive' : ''}`} type="submit" style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;{(editRowData as any) != '' ? <>Update</> : <>Save & New</>}</button>&nbsp;
                    {/* <button className={`btn123 ${disable || editable || (editRowData as any) != '' ? 'inactive' : ''}`} onClick={() => { handelSubmit; setIssave(true) }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;Save</button> */}
                    <button className={`btn123 ${disable || editable || (editRowData as any) != '' ? 'inactive' : ''}`} onClick={(event) => { handelSubmit(event); setIssave(true) }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;Save</button>
                </form>

                <button className={`btn123 ${disable ? '' : 'inactive'}`} onClick={() => {
                    setDisable(false); setEditable(false);
                    (editRowData as any) = "";
                    setInputs({});
                    setUse(false);

                }} style={{ paddingRight: 12 }}><Add />&nbsp;Add</button>&nbsp;
                &nbsp;
                <button className={`btn123 ${disable ? 'inactive' : ''}`} onClick={() => { setIssave(true); closeForm() }}  ><Close />&nbsp; Close</button>

            </div>


        </div>
    </>);



}

export default AddCity;


