import { commanServicer } from "../Commen_Services/common.services";

export const stateMasterService = {
    SaveorUpdate,
    getstatedatabyid,
   
}

async function SaveorUpdate(MasterData : any) {
    console.log(MasterData);
    return await commanServicer.fetchRequest(JSON.stringify(MasterData), true, 'StateMaster/saveorupdatestatedetails','POST');
}

async function getstatedatabyid(id : any) {
   
    return await commanServicer.fetchRequest('',true,'StateMaster/getstatedatabyid?id='+id,'GET');
}

