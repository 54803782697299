import { commanServicer } from "../Commen_Services/common.services";

export const designationService = {
    SaveorUpdate
   
}

async function SaveorUpdate(MasterData : any) {
    console.log(MasterData);
    return await commanServicer.fetchRequest(JSON.stringify(MasterData), true, 'DesignationMaster/saveupdate','POST');
}