import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../Commen_Component/Dashboard/Dashboard';
import MasterRouting2 from '../Masters/MasterRouting2';
import EnhancedPage from '../Commen_Component/EnhancedPage';
import PageNotFound from '../../pages/PageNotFound';
import ReportModule from '../Reports/ReportModule';

interface CposRoutingmoduleProps {
    onLogout: () => void;
}

const CposRoutingmodule: React.FC<CposRoutingmoduleProps> = ({ onLogout }) => {
    return (
        <Routes>
            <Route path="/" element={<Dashboard onLogOut={onLogout} />} >
                <Route path="/Master/:componentName" element={<MasterRouting2 />} >
                    <Route path=':action' element={<MasterRouting2 />} />
                </Route>
                {/* <Route path="/Report/:componentName/:pageId" element={<ReportModule />} /> */}
                <Route path="/Report" element={<ReportModule />} >
                    <Route path=":componentName/:pageId" element={<ReportModule />} />
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Route>

        </Routes>
    );
};

export default CposRoutingmodule;
