import React, { useEffect, useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FaFilter } from "react-icons/fa";
import { TextField, FormControl, InputLabel, MenuItem, Select, Chip, Stack, Autocomplete } from "@mui/material";
import Popfilterform from "../Commen_Component/Popup/Popupfilterform";
import ExportToCSV from "../Commen_Component/Export/ExportToCSV";
import { userServicer } from "../Commen_Services/user.services";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PageEnums } from "../../_enums/enum.services";

interface ColumnData {
    autoSearchKey: string;
    tableFieldName: string;
    headerName: string;
}

interface RowData {
    [key: string]: any;
}

interface reportData {
    pageId: Number;
    reportName: string;
    exportReportName: string;
    viewButtonsSum: string;
    hasFixParameter: string;
    defaultSearchList: any[];
    cmdType: Number;
}

interface ZotaHeaderSearchProps {
    pageID: string;
    columnData: ColumnData[];
    tableData: RowData[];
    sendFilterData: ({ key: string; value: any }[]);
    sendDataToParent: (event: { key: string; value: any }[]) => void;
    //sendDataToParent: ({ key: string; value: any }[]);
    ReportConfigData: RowData[];
    //ReportConfigData: reportData[];
    csvHeaderColumns: RowData[];

}

const EnhancedSearchbar: React.FC<ZotaHeaderSearchProps> = ({ pageID, columnData, sendFilterData, sendDataToParent, ReportConfigData, tableData, csvHeaderColumns }) => {
    const [selectedField, setSelectedField] = useState<string>("");
    const [selectedField2, setSelectedField2] = useState<string>("");
    const [filterData, setFilterData] = useState<{ key: string; value: any }[]>([]);
    const [filterDataforChips, setFilterDataforChips] = useState<{ key: string; value: any }[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [tableColumns, setTableColumns] = useState<ColumnData[]>(columnData);
    const [showDrop, setshowDrop] = useState(false);
    const [options, setOptions] = useState([]);
    const [dropDownField, setDropDownField] = useState("");
    const navigate = useNavigate();
    const [changeselectedField, setChangeSelectedField] = useState(true);
    const [datalength, setDatalength] = useState(0);
    const [reportName, setReportName] = useState();

    const handleDatafromPOP = (data: { key: string; value: any }[]) => {

        let updatedFilterData = [...filterData];


        data.forEach(({ key, value }) => {

            const index = updatedFilterData.findIndex(item => item.key === key);


            if (index !== -1) {

                if (value == "") {
                    //updatedFilterData.pop(index);
                    updatedFilterData.splice(index, 1); 

                }
                else {
                    updatedFilterData[index] = { key, value };
                }
            } else {

                updatedFilterData.push({ key, value });
            }

           
        });


        setFilterData(updatedFilterData);
    };

    const handleDatafromPOPforchips = (data: { key: string; value: any }[]) => {
        let updatedFilterData = [...filterDataforChips];


        data.forEach(({ key, value }) => {

            const index = updatedFilterData.findIndex(item => item.key === key);


            if (index !== -1) {

                if (value == "") {
                    //updatedFilterData.pop(index);
                    updatedFilterData.splice(index, 1); 
                }
                else {
                    updatedFilterData[index] = { key, value };
                }
            } else {

                updatedFilterData.push({ key, value });
            }
        });

        console.log(updatedFilterData);
        console.log(columnData);


        setFilterDataforChips(updatedFilterData);
    }

    useEffect(() => {
        console.log((ReportConfigData as any).exportReportName);
        setReportName((ReportConfigData as any).exportReportName);
    },[ReportConfigData]);

    useEffect(() => {
        setDatalength(filterData.length);
        sendDataToParent(filterData);
    }, [filterData]);


    // const handleChangeMUI = (e: React.ChangeEvent<{ value: unknown }>) => {
        const handleChangeMUI = (e: any) => {

        setSelectedField(e.target.value as string);
        let h2 = columnData.filter(item => item.tableFieldName == e.target.value);
        setSelectedField2(h2[0].headerName as string)
        // setSelectedField(e.target.value.tableFieldName as string);
        // setSelectedField2(e.target.value.headerName as String);
        // console.log(columnData);
        const h1 = columnData.filter(item => item['tableFieldName'] == e.target.value);
        console.log(h1);
        debugger
        if (h1[0].autoSearchKey != null) {

            setshowDrop(true);
            setDropDownField(h1[0].autoSearchKey as string)
        }
        else {
            setshowDrop(false);
        }


    };

    const handleDelete = (index: number) => {
        const newData = [...filterData];
        const newDataforChips = [...filterDataforChips];
        newData.splice(index, 1);
        newDataforChips.splice(index, 1);
        setFilterData(newData);
        setFilterDataforChips(newDataforChips)
        sendDataToParent(newData);

        setshowDrop(false);
    };

    const handleAddButton = () => {
 
        //let enumkey = Object.keys(PageEnums)[Object.values(PageEnums).indexOf(key)];
        debugger
        let url = '/';
        let enumkey = Object.keys(PageEnums).filter(item => item == reportName);
        if(enumkey[0] == null || enumkey[0] == undefined)
        {
            url = String("Add/").concat(pageID);
        }
        else
        {
            url   = String("Add").concat(enumkey[0])  
        }
        
         
        navigate(url);
    }

    useEffect(() => {
        debugger
        if (datalength == 1) {


            if (filterData[0] != undefined) {
                console.log(filterData[0]);
                setSelectedField(filterData[0].key as string);
                let h1 = tableColumns.filter(item => item.tableFieldName == filterData[0].key);
                console.log(filterData.find(item => item.key === "designationCode")?.value);
                 
                if (h1[0].autoSearchKey != null) {

                    setshowDrop(true);
                    setDropDownField(h1[0].autoSearchKey as string)
                }
                else {
                    setshowDrop(false);
                }


            }

        }



    }, [filterData, datalength]);


    useEffect(() => {
        if (datalength == 1) {


            if (filterDataforChips[0] != undefined) {
                setSelectedField2(filterDataforChips[0].key);
            }

        }
    }, [filterDataforChips, datalength])

    useEffect(() => {
        debugger
        changeselectedField ? setChangeSelectedField(false) : setChangeSelectedField(true);
        setDatalength(0)
    }, [selectedField])

    useEffect(() => {
        console.log(columnData);

        const filteredColumnData = columnData != undefined && columnData.filter((item: any) => item.showFieldDDL === true);

        setTableColumns(filteredColumnData as ColumnData[]);
    }, [columnData]);

    useEffect(() => {
        const fetcOption = async () => {
            debugger
            const data = await userServicer.getDropDownlist(dropDownField, null);
            const h = data.data;
            const transformedData = h.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(h[0])[0]],
                OptionName: item[Object.keys(h[0])[1]] == undefined ? item[Object.keys(h[0])[0]] : item[Object.keys(h[0])[1]]
            }));
            setOptions(transformedData);
        }
        if (dropDownField != null || dropDownField != undefined) {
            debugger
            fetcOption();
        }
    }, [dropDownField])

   
    let val3  = {OptionName: ''};                 
    if (options !== undefined && options !== null && filterData !== undefined && filterData !== null) {
        let val2 = options.filter((item: any) => item.OptionID ==  filterData[0]?.value);
        console.log(val2);
        let val4 = val2.length > 0 ? Object.values(val2[0]) : '';
        console.log(val4[1]);
        val3.OptionName = val4[1] != undefined ? String(val4[1]) : '';
    } else {
        console.log('options or inputs[field.tableFieldName] is undefined or null');
    } 


    return (
        <div style={{ width: '100%' }}>
            <div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FormControl variant="standard" sx={{ m: 1, width: 200, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-standard-label" sx={{
                                    fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                                }}> Search Field </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedField}
                                    // value={filterData.length > 0? typeof selectedField : selectedField.tableFieldName }
                                    onChange={handleChangeMUI}
                                    label="Search Field"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                >
                                    {tableColumns.map((option, i) => (
                                        <MenuItem key={i} value={option.tableFieldName}>{option.headerName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div style={{ marginTop: '3px' }}>
                                {
                                    showDrop ?
                                        <>
                                            {/* <FormControl variant="standard" sx={{ m: -0.3, width: 200, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label" sx={{
                                                fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                                            }}>
                                                {selectedField}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                autoComplete="inside"
                                                // name={field.tableFieldName}
                                                //onChange={handleChangeMUI}
                                                onChange={(e) => {handleDatafromPOP([{ key: selectedField, value: e.target.value }])
                                                                    handleDatafromPOPforchips([{ key: selectedField2 , value: e.target.value }])}
                                                }
                                                label="Search c"
                                                onClose={(e) => e.preventDefault()}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 300, // Set your desired max height
                                                            overflowY: 'auto', // Enable vertical scrolling
                                                        },
                                                    },

                                                }}
                                            >
                                                {options.map((item, i) => {
                                                    console.log(item);

                                                    return (<MenuItem key={i} sx={{ fontSize: 14 }} value={item['OptionID']}>{item['OptionName']}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl> */}


                                            <Autocomplete

                                                disablePortal
                                                id="combo-box-demo"
                                                options={options}
                                                //value={filterData.find(item => item.key === "designationCode")?.value || ''}
                                                //value={filterData ? options.find(item => item.OptionName == "HR") : ''}
                                                value={val3}
                                                onChange={(event, newValue: any) => {
                                                    handleDatafromPOP([{ key: selectedField, value: newValue != null ? newValue.OptionID : '' }])
                                                    handleDatafromPOPforchips([{ key: selectedField2, value: newValue != null ? newValue.OptionName : '' }])
                                                }
                                                }
                                                getOptionLabel={(option: any) => option.OptionName}
                                                getOptionKey={(option: any) => option.OptionID}
                                                sx={{ width: 180, overflowY: 'auto', overflowX: 'hidden', marginRight: 1 }}
                                                //value={inputs.stateId != "" ? Number(inputs.stateId) : inputs.stateId }
                                                renderInput={(params) => (
                                                    <TextField

                                                        {...params}
                                                        label="State Name"
                                                        variant="standard"
                                                        name="stateId"
                                                        InputLabelProps={{
                                                            style: {
                                                                color: '#243C5C',
                                                                fontSize: '12px',
                                                                borderColor: 'red',

                                                            },
                                                        }}

                                                    />
                                                )}
                                            />
                                        </>


                                        : <TextField
                                            variant="standard"
                                            value={filterDataforChips.find(item => item.key === selectedField2)?.value || ''}
                                            label="search"
                                            onChange={(e) => {
                                                handleDatafromPOP([{ key: selectedField, value: e.target.value }]);
                                                handleDatafromPOPforchips([{ key: selectedField2, value: e.target.value }])
                                            }}

                                            InputLabelProps={{
                                                style: {
                                                    color: '#243C5C',
                                                    fontSize: '12px',
                                                    borderColor: 'red',

                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    width: '200px',
                                                }
                                            }}
                                        />
                                }

                            </div>
                            <div className="center-pop">
                                <button className="btn12" onClick={() => setOpen(true)}><FaFilter /></button>
                                <ExportToCSV csvData={tableData} headerName={csvHeaderColumns} fileName={(ReportConfigData as any).exportReportName} />
                                <button className="btn12" onClick={() => handleAddButton()}><Add /></button>
                                <Popup contentStyle={{ height: 250, width: 450, padding: 0 }} open={open} onClose={() => setOpen(false)}>
                                    <Popfilterform pageID={pageID} columndata={tableColumns} ReportConfigData={ReportConfigData} filterformData={filterData} sendDataToParent={handleDatafromPOP} sendDataToParentforChips={handleDatafromPOPforchips} openClose={() => setOpen(false)} />
                                </Popup>
                            </div>
                        </div>
                        <div className="chips-container">
                            <Stack direction="column" spacing={1}>
                                {filterDataforChips.map((item, index) => (
                                    //<Chip key={index} label={`${item.key} : ${item.value}`} onDelete={() => handleDelete(index)} />
                                    <Chip sx={{ backgroundColor: '#d3eafc', border: '1px solid #243c5c', color: '#243c5c', fontWeight: '500' }} label={`${item.key} : ${item.value}`} variant="outlined" onDelete={() => handleDelete(index)} />

                                ))}
                            </Stack>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EnhancedSearchbar;

