import { Add, Check, Close, Save } from "@mui/icons-material";
import { Alert, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { designationService } from "../../Commen_Services/Designation.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import customToast from "../../Commen_Component/Toast/CustomToast";

interface propsHead {
    columndata: any[];
    editRowData: any[];
    //editRowData: RowData[];
    sendDatatoParent: any;
    refreshGrid: any;
    sendViewStatus: any;
}

interface RowData {
    [key: string]: any;
}

const AddDesignation: React.FC<propsHead> = ({ columndata, editRowData, sendDatatoParent, refreshGrid, sendViewStatus }) => {

    const [inputs, setInputs] = useState<any>({});

    const [disable, setDisable] = useState(true);
    const [editable, setEditable] = useState(true);
    const [error, setError] = useState({
        designationCode: '',
        designationName: '',
        levelId: ''
    });
    const [use, setUse] = useState(true);
    const [isSave, setIssave] = useState(false);






    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        let newInputs;
        if (name == 'levelId') {
            const value1 = parseInt(value);
            newInputs = { ...inputs, [name]: value1 };
        }
        else {
            newInputs = { ...inputs, [name]: value };
        }

        let newError = { ...error };

        // Validation logic
        if (name === 'designationCode') {
            if (!/^[a-zA-Z0-9]{1,10}$/.test(value)) {
                newError.designationCode = "Code should be alphanumeric & maximum of 10 characters";
            } else {
                newError.designationCode = '';
            }
        } else if (name === 'designationName') {
            if (!/^[a-zA-Z\-_]{0,50}$/.test(value)) {
                newError.designationName = "Name should be Alphabetic & maximum of 50 characters";
            } else {
                newError.designationName = '';
            }
        } else if (name === 'levelId') {
            const value1 = parseInt(value)
            debugger
            if (value1 > 100) {
                newError.levelId = "Maximum of 2 digits";
            }
            else if (value1 < 0) {
                newError.levelId = "LevelId must be positive";
            }
            else {
                newError.levelId = '';
            }
        }

        // Update state
        setInputs(newInputs);
        setError(newError);
    };


    const handleFocus = (fieldName: any) => {
        if (!inputs[fieldName]) {
            setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter " + fieldName }));
        }
    };

    // const handelSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const handelSubmit = (event: any) => {
        event.preventDefault();
        console.log(inputs);
        console.log(error);

        if (!/^[a-zA-Z0-9]{1,10}$/.test(inputs.designationCode)) {
            //setInputs(values => ({ ...values, designationCode: value }));
            setError({ ...error, designationCode: "Code should be alphanumeric & maximum of 10 char" });

        }
        if (!/^[a-zA-Z0-9\-_]{0,50}$/.test(inputs.designationName)) {
            // setInputs(values => ({ ...values, [name]: value }));
            setError({ ...error, designationName: "Name should be alphanumeric & maximum of 50 char" });
        }

        if (inputs.levelId && inputs.levelId.length > 2) {
            //setInputs(values => ({ ...values, [name]: value }));
            setError({ ...error, levelId: "Maximum of 2 digits" });
        }
        if (!inputs.designationCode) {
            setError((prevErrors) => ({ ...prevErrors, designationCode: "Please enter designationCode" }));
        }
        if (!inputs.designationName) {
            setError((prevErrors) => ({ ...prevErrors, designationName: "Please enter designationName" }));
        }
        if (!inputs.levelId) {
            setError((prevErrors) => ({ ...prevErrors, levelId: "Please enter levelId" }));
        }

        if (inputs.designationCode != undefined && inputs.designationName != undefined && inputs.levelId != undefined) {
            if (error.designationCode == '' && error.designationName == "" && error.levelId == "") {
                try {

                    console.log(inputs);
                    debugger
                    designationService.SaveorUpdate(inputs).then(user => {
                        if (user.statusCode == 200) {
                            //customToast.showToast("Warning: Something went wrong", "warning");
                            customToast.success("Data saved Successfully");
                            <Alert severity="success">This is a success Alert.</Alert>
                            closeForm();
                            refreshGrid(true);
                            // setInputs({});
                            // setDisable(true);
                        }
                    });
                }
                catch (ex) {
                    customToast.error("Something went wrong");
                }
            }



        }


    }

    const closeForm = () => {

        setInputs({ id: 0, isActive: true, designationCode: '', designationName: '', levelId: '', isViewData: false });
        setError({
            designationCode: '',
            designationName: '',
            levelId: ''
        });
        sendDatatoParent("");
        sendViewStatus(false);
        debugger
        if (isSave) {
            setDisable(true);
            setEditable(true);
        }

    }

    useEffect(() => {
        debugger
        setInputs((values:any) => ({ ...values, id: 0, isActive: true }));
        if ((editRowData as any)!= "") {

            setInputs(editRowData);
            setDisable(false);
            (editRowData as any).isViewData ? setEditable(true) : setEditable(false);

        }

    }, [editRowData, use])

    useEffect(() => {
        closeForm();
    }, [isSave]);

    return (<>
        <div style={{ marginLeft: 10 }}>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <form onSubmit={handelSubmit} style={{ display: 'flex', flexDirection: 'row' }} >
                    <TextField
                        sx={{ marginRight: 1 }}
                        id="standard-basic"
                        variant="standard"
                        name="designationCode"
                        disabled={disable}
                        value={inputs["designationCode"]}
                        label="Designation Code"
                        onChange={handleChange}
                        focused={!!inputs.designationCode}
                        onFocus={() => handleFocus('designationCode')}
                        error={!!error.designationCode}
                        helperText={error.designationCode}
                        InputProps={{
                            readOnly: editable,
                            style: {
                                color: '#243C5C',
                                fontSize: '14px',
                                width: '200px',
                                borderColor: error.designationCode ? 'red' : undefined
                            }
                        }}
                        InputLabelProps={{

                            style: {
                                color: '#243C5C',
                                fontSize: '12px',
                                borderColor: error.designationCode ? 'red' : undefined
                            },
                        }}

                        FormHelperTextProps={{
                            style: {
                                fontSize: error.designationCode ? '9px' : '12px',

                            },
                        }}

                    />

                    <TextField

                        sx={{ marginRight: 1 }}
                        id="standard-basic"
                        variant="standard"
                        name="designationName"
                        value={inputs["designationName"]}
                        label="Designation Name"
                        disabled={disable}
                        onChange={handleChange}
                        focused={!!inputs.designationName}
                        onFocus={() => handleFocus("designationName")}
                        error={!!error.designationName}
                        helperText={error.designationName}
                        InputProps={{
                            readOnly: editable,
                            style: {
                                color: '#243C5C',
                                fontSize: '14px',
                                width: '200px',
                                borderColor: error.designationName ? 'red' : undefined
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#243C5C',
                                fontSize: '12px',
                                borderColor: error.designationName ? 'red' : undefined
                            },
                        }}
                        FormHelperTextProps={{
                            style: {
                                fontSize: error.designationName ? '9px' : '12px',

                            },
                        }}
                    />
                    <TextField
                        sx={{ marginRight: 1 }}
                        id="standard-basic"
                        variant="standard"
                        name="levelId"
                        type="number"
                        disabled={disable}
                        value={inputs["levelId"]}
                        label="Level Id"
                        onChange={handleChange}
                        focused={!!inputs.levelId}
                        onFocus={() => handleFocus("levelId")}
                        error={!!error.levelId}
                        helperText={error.levelId}
                        InputProps={{
                            inputProps: { min: 1 },
                            readOnly: editable,
                            style: {
                                color: '#243C5C',
                                fontSize: '14px',
                                width: '200px',
                                borderColor: error.levelId ? 'red' : undefined
                            }
                        }}

                        InputLabelProps={{
                            style: {
                                color: '#243C5C',
                                fontSize: '12px',
                                borderColor: error.levelId ? 'red' : undefined
                            },
                        }}
                        FormHelperTextProps={{
                            style: {
                                fontSize: error.levelId ? '9px' : '12px',

                            },
                        }}
                    />
                    <button className={`btn123 ${disable || editable ? 'inactive' : ''}`} type="submit" style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;{(editRowData as any) != '' ? <>Update</> : <>Save & New</>}</button>&nbsp;
                    <button className={`btn123 ${disable || editable || (editRowData as any) != '' ? 'inactive' : ''}`} onClick={(event) => { handelSubmit(event); setIssave(true) }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;Save</button>
                </form>

                <button className={`btn123 ${disable ? '' : 'inactive'}`} onClick={() => {
                    setDisable(false); setEditable(false); console.log(editRowData);
                    (editRowData as any) = ""; console.log(editRowData);
                    setInputs({});
                    setUse(false);

                }} style={{ paddingRight: 12 }}><Add />&nbsp;Add</button>&nbsp;
                &nbsp;
                <button className={`btn123 ${disable ? 'inactive' : ''}`} onClick={() => { setIssave(true); closeForm() }}  ><Close />&nbsp; Close</button>

            </div>


        </div>
    </>);



}

export default AddDesignation;


