import React from 'react';
import { useParams } from 'react-router-dom';
import EnhancedPage from '../../Commen_Component/EnhancedPage'; // Ensure correct import path

const View = () => {

    const { pageId } = useParams();

    console.log("PageID" + pageId);
    
    return <EnhancedPage pageId={pageId} />;
};

export default View;




