
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageNotFound from '../../pages/PageNotFound';

import Headquater from '../Masters/Headquater/Headquater';
import Designation from './Designation/Designation';
import EnhancedPage from '../Commen_Component/EnhancedPage';
import { PageEnums } from '../../_enums/enum.services';
import AddHeadquater from './Headquater/AddHeadquater';
import AddDesignation from './Designation/AddDesignation';


const componentPaths: any = {
    "Headquarter": Headquater,
    "Designation": Designation,
};

const componentActionPaths: any = {
    "AddHeadquarter": AddHeadquater,

}

const MasterRouting2 = () => {
    const { componentName } = useParams<{ componentName: string }>();
    const { action } = useParams<{ action: string }>();
    const [Component, setComponent] = useState<React.ComponentType<any> | null>(null);
    const [pageId, setPageId] = useState(0);

    useEffect(() => {

        const importComponent = async () => {
            debugger
            if (action != null) {
                if (componentActionPaths[action]) {
                    setComponent(() => componentActionPaths[action]);
                }
                else {
                    setComponent(() => null)
                }
            }
            else {
                if (componentName != null) {
                    if (componentPaths[componentName]) {
                        setComponent(() => componentPaths[componentName]);
                    }
                    else {
                        setComponent(() => null)
                    }

                }

            }

        };
        importComponent();
    }, [componentName, action]);

    if (!Component) return <PageNotFound />;

    return <Component />

};

export default MasterRouting2;




